* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
a:focus {
  outline: none;
}
#root {
  height: 100%;
}

.flex-grow {
  flex-grow: 1 !important;
}
