$font-primary: 'Open Sans', Arial, sans-serif;
$font-secondary: 'Dancing Script', cursive;
// Overrides
$grid-gutter-width: 40px; 
$border-radius-base:  4px;
$padding-base-vertical: 14px;

$brand-primary: #2cc9d7;
 

$brand-white: #fff;
$brand-black: #000;
$brand-lighter: #848484;
$brand-darker: #5a5a5a;
$brand-selection-color: $brand-primary;
$brand-body-color: #fcfcfc;
$brand-body-bg: #fff;
$brand-dark-footer: #222831;
$brand-light-color: #F6FAF7;


$input-border-focus: $brand-primary;
$form-group-margin-bottom: 30px;



// Mixin
@mixin fh5co-translateX($translatex) {
	-moz-transform: translateX($translatex);
	-webkit-transform: translateX($translatex);
	-ms-transform: translateX($translatex);
	-o-transform: translateX($translatex);
	transform: translateX($translatex);
}

@mixin transition($transition, $ease: 'ease') {
    -moz-transition:    all $transition unquote($ease);
    -o-transition:      all $transition unquote($ease);
    -webkit-transition: all $transition unquote($ease);
    -ms-transition: 		all $transition unquote($ease);
    transition:         all $transition unquote($ease);
}
@mixin inline-block() {
	display:-moz-inline-stack;
	display:inline-block;
	zoom:1;
	*display:inline;
}
@mixin flex() {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
@mixin flex() {
	display: -webkit-box;      
  	display: -moz-box;         
  	display: -ms-flexbox;      
  	display: -webkit-flex;     
  	display: flex;             
}
@mixin flexwrap() {
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap; 
	-moz-flex-wrap: wrap; 
}

@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon/icomoon.eot?srf3rx');
	src:url('../fonts/icomoon/icomoon.eot?srf3rx#iefix') format('embedded-opentype'),
		url('../fonts/icomoon/icomoon.ttf?srf3rx') format('truetype'),
		url('../fonts/icomoon/icomoon.woff?srf3rx') format('woff'),
		url('../fonts/icomoon/icomoon.svg?srf3rx#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

@mixin icomoon() {
	
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
}

// Import 
@import 'bootstrap/mixins';
@import 'bootstrap/variables';




/* =======================================================
*
* 	Template Style 
*	Edit this section
*
* ======================================================= */

// Template Base

body {
	font-family: $font-primary;
	line-height: 1.6;
	font-size: 16px;
	background: $brand-body-bg;
	color: #848484;
	font-weight: 300;
	overflow-x: hidden;
	&.fh5co-offcanvas {
		overflow: hidden;
	}
}
a {
	color: $brand-primary;
	@include transition(.5s, ease);
	&:hover{
		text-decoration: none ;
		color: $brand-primary;
	}
}
p, ul, ol {
	margin-bottom: 1.5em;
	font-size: 16px;
	color: $brand-lighter;
	// font-weight: 300;
	font-family: $font-primary;
}

h1, h2, h3, h4, h5, h6 {
	color: $brand-darker;
	font-family: $font-secondary;
	font-weight: 400;
	margin: 0 0 30px 0;
}
figure {
	margin-bottom: 2em;
}
::-webkit-selection {
  color: $brand-body-color;
  background: $brand-selection-color;
}

::-moz-selection {
  color: $brand-body-color;
  background: $brand-selection-color;
}

::selection {
  color: $brand-body-color;
  background: $brand-selection-color;
}

#fh5co-header{
	position: relative;
	margin-bottom: 0;
	z-index: 9999999;
}
.stuck {
  position:fixed!important;
  top:0!important;
  -webkit-box-shadow:0 2px 2px rgba(0,0,0,0.05);
	-moz-box-shadow:0 2px 2px rgba(0,0,0,0.05);
	-ms-box-shadow:0 2px 2px rgba(0,0,0,0.05);
	-o-box-shadow:0 2px 2px rgba(0,0,0,0.05);
	box-shadow:0 2px 2px rgba(0,0,0,0.05);
}
.header-top {
	padding: 10px 0;
	background: $brand-primary;
	.fh5co-link {

		a {
			margin-left: 10px;
			font-size: 13px;
			color: rgba(255,255,255,.7);
			&:first-child {
				margin-left: 0;
			}
			&:hover {
				color: rgba(255,255,255,1)!important;
			}
		}
		@media screen and (max-width: $screen-sm) {
			text-align: center;
		}
	}
	.fh5co-social {
		a {
			color: rgba(255,255,255,.7);
			font-size: 14px;
			margin-right: 15px;
			position: relative;
			@include transition(.5s, ease-in-out);
			
			&:last-child {
				margin-right: 0;
			}
			&:hover, &:focus, &:active {
				text-decoration: none;
				color: rgba(255,255,255,1)!important;
				i {
					-webkit-transform: scale(1.2);
					-moz-transform: scale(1.2);
					-ms-transform: scale(1.2);
					-o-transform: scale(1.2);
					transform: scale(1.2);
				}
			}
		}
		@media screen and (max-width: $screen-sm) {
			text-align: center!important;
		}
	}
}
.fh5co-cover,
.fh5co-hero {
	position: relative;
	height: 800px;
	width: 100%;
	@media screen and (max-width :$screen-sm) {
		height: 700px;
	}
}
.fh5co-overlay{
	position: absolute !important;
	width: 100%;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1;
	background: rgba(0, 0, 0, .4);
}

.fh5co-cover{
	background-size: cover;
	position: relative;
	@media screen and (max-width: $screen-sm) {
		heifght: inherit;
		padding: 3em 0;
	}
	.display-t{
		display: table;
		height: 800px;
		width: 100%;
		position: relative;
		z-index: 2;
		@media screen and (max-width :$screen-sm) {
			height: 600px;
		}
	}
	.display-tc{
		display: table-cell;
		vertical-align: middle;
		h1{
			color: $brand-white;
		}
		h2{
			color: $brand-white;
			font-size: 80px;
			margin-bottom: 30px;
			font-weight: 300!important;
			strong {
				font-weight: 700;
			}
			@media screen and (max-width: $screen-sm) {
				font-size: 60px;
			}
		}
		p{
			color: $brand-white;
			font-weight: 400;
			font-size: 16px;
			letter-spacing: 7px;
			span{
				border-top: 2px solid $brand-white;
				border-bottom: 2px solid $brand-white;
				padding: 5px 0px;
				line-height: 4ex;
			}
		}
	}
}

/* Superfish Override Menu */
.sf-menu {
	margin: 7px 0 0 0!important;
}

.sf-menu {
	float: right;
}
.sf-menu ul {
	box-shadow: none;
	border: transparent;
	min-width: 12em; 
	*width: 12em; 
}

.sf-menu a {
	color: rgba(0,0,0,.5) !important;
	padding: .75em 1em!important;
	font-weight: normal;
	border-left: none;
	border-top: none; 
	border-top: none;
	text-decoration: none;
	zoom: 1; 
	font-size: 16px;
	border-bottom: none!important;

	&:hover,&:focus{
		color: $brand-primary!important;
	}
}
.sf-menu li.active{
	a{
		&:hover,&:focus{
			color: $brand-primary !important;
		} 
	}
}

.sf-menu li,
.sf-menu ul li,
.sf-menu ul ul li,
.sf-menu li:hover,
.sf-menu li.sfHover  {
	background: transparent !important;
}
.sf-menu ul li a,
.sf-menu ul ul li a {
	text-transform: none !important;
	padding: .20em 1em!important;	
	letter-spacing: 1px !important;
	font-size: 14px!important;
}
.sf-menu li:hover a,
.sf-menu li.sfHover a,
.sf-menu ul li:hover a,
.sf-menu ul li.sfHover a,
.sf-menu li.active a {
	color: $brand-primary !important;
	// text-shadow: 2px 2px 1px rgba(0, 0, 0, .08);
}
.sf-menu ul li:hover,
.sf-menu ul li.sfHover {
	background: transparent !important;
}


.sf-menu ul li {
	background: transparent !important;
}
.sf-arrows .sf-with-ul {
	padding-right: 2.5em;
	*padding-right: 1em;
}
.sf-arrows .sf-with-ul:after {
	content: '';
	position: absolute;
	top: 50%;
	right: 1em;
	margin-top: -3px;
	height: 0;
	width: 0;
	border: 5px solid transparent;
	border-top-color: #ccc; 
}

.sf-arrows > li > .sf-with-ul:focus:after,
.sf-arrows > li:hover > .sf-with-ul:after,
.sf-arrows > .sfHover > .sf-with-ul:after {
	border-top-color: #ccc; 
}
.sf-arrows ul .sf-with-ul:after {
	margin-top: -5px;
	margin-right: -3px;
	border-color: transparent;
	border-left-color: #ccc;
}
.sf-arrows ul li > .sf-with-ul:focus:after,
.sf-arrows ul li:hover > .sf-with-ul:after,
.sf-arrows ul .sfHover > .sf-with-ul:after {
	border-left-color: #ccc;
}

#fh5co-header-section{
	padding:0;
	position: relative;
	left: 0;
	width: 100%;
	z-index: 1022;
	background: $brand-white;
}
.nav-header{
	
	position: relative;
	float: left;
	width: 100%;
}

#fh5co-logo{
	position: relative;
	z-index: 2;
	font-size: 30px;
	margin-top: 35px;
	font-weight: 700;
	float: left;
	span{
		font-weight: 700;
	}

	@media screen and (max-width: $screen-sm) {
		width: 100%;
		float: none;
		text-align: center;
	}

	a{
		color: $brand-primary;
		&:hover,&:focus{
			color: $brand-primary !important;
		}
	}
	
}

#fh5co-menu-wrap {
	position: relative;
	z-index: 2;
	display: block;
	float: right;
	margin-top: 15px;
	.sf-menu a {
		padding: 1em .75em;
	}
}
#fh5co-primary-menu {
	> li {
		> ul {
			li {
				&.active > a {
					color: $brand-primary!important;
				}
			}
		}
		> .sf-with-ul {
			&:after {
				border: none!important;
				@include icomoon();
				position: absolute;
				float: right;
				margin-right: -0px;
				top: 50%;
				font-size: 12px;
				margin-top: -7px;
				content: "\e922";
				color: rgba(0,0,0,.5);
			}
		}
		> ul {
			li {
				position: relative;
				> .sf-with-ul {
					&:after {
						border: none!important;
						@include icomoon();
						position: absolute;
						float: right;
						margin-right: 10px;
						top: 20px;
						font-size: 12px;
						content: "\e924";
						color: rgba(0,0,0,.5);
					}
				}
			}
		}
	}	
}
#fh5co-primary-menu .fh5co-sub-menu {
	padding:10px 7px 10px;
	background: $brand-white;
	left: 0;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	border-radius: 5px;
	-webkit-box-shadow:0 5px 15px rgba(0,0,0,0.15);
	-moz-box-shadow:0 5px 15px rgba(0,0,0,0.15);
	-ms-box-shadow:0 5px 15px rgba(0,0,0,0.15);
	-o-box-shadow:0 5px 15px rgba(0,0,0,0.15);
	box-shadow:0 5px 15px rgba(0,0,0,0.15);
	> li {
		> .fh5co-sub-menu {
			left: 100%;
		}	
	}
	
}

#fh5co-primary-menu .fh5co-sub-menu:before {
	position:absolute;
	top:-9px;
	left:20px;
	width:0;
	height:0;
	content:'';
}

#fh5co-primary-menu .fh5co-sub-menu:after {
	position:absolute;
	top:-8px;
	left:21px;
	width:0;
	height:0;
	border-right:8px solid transparent;
	border-bottom:8px solid $brand-white;
	border-bottom:8px solid rgba(255, 255, 255, 1);
	border-left:8px solid transparent;
	content:'';
}

#fh5co-primary-menu .fh5co-sub-menu .fh5co-sub-menu:before {
	top:6px;
	left:100%;
}

#fh5co-primary-menu .fh5co-sub-menu .fh5co-sub-menu:after {
	top:7px;
	right:100%;
	border: none!important;
}

.site-header.has-image #primary-menu .sub-menu {
	border-color:#ebebeb;
	-webkit-box-shadow:0 5px 15px 4px rgba(0,0,0,0.09);
	-moz-box-shadow:0 5px 15px 4px rgba(0,0,0,0.09);
	-ms-box-shadow:0 5px 15px 4px rgba(0,0,0,0.09);
	-o-box-shadow:0 5px 15px 4px rgba(0,0,0,0.09);
	box-shadow:0 5px 15px 4px rgba(0,0,0,0.09);
	&:before {
		display:none;	
	}
}
#fh5co-primary-menu .fh5co-sub-menu a {
	letter-spacing: 0;
	padding:0 15px;
	font-size:18px;
	line-height:34px;
	color: #575757 !important;
	text-transform:none;
	background:none;
	&:hover {
		color: $brand-primary!important;
	}
}

#fh5co-couple,
#fh5co-countdown,
#fh5co-groom-bride,
#fh5co-blog-section,
#fh5co-started,
#fh5co-when-where,
#fh5co-guest,
#fh5co-gallery,
.fh5co-content-section {
	padding: 7em 0;
	@media screen and (max-width: $screen-sm) {
		padding: 4em 0;
	}

}
.fh5co-section-gray{
	background: $brand-light-color;
}

.heading-section{
	padding-bottom: 1em;
	margin-bottom: 3em;

	h2{
		font-size: 50px;
		font-weight: 300;
		position: relative;
		color: $brand-primary;
	}
	p {
		font-size: 18px;
	}
}

#fh5co-couple{
	img{
		margin-bottom: 20px;
		@include border-radius(50%);
	}
	h3{
		font-size: 30px;
		@media screen and (max-width :$screen-sm) {
			font-size: 35px;
		}
	}
	.amp-center{
		font-size: 60px;
		margin-top: 100%;
		color: rgba($brand-primary,1);
		@media screen and (max-width :$screen-sm) {
			font-size: 40px;
		}
	}
}

#fh5co-countdown{
	background: $brand-primary;
	.countdown{
		margin-bottom: 0;
		font-size: 80px;
		color: $brand-white;
		span{
			margin: 0 30px;
			display: inline-block;
			font-family: $font-secondary;
		}
		small{
			display: block;
			font-size: 16px;
			font-family: $font-primary;
		}
	}
}

.couple-wrap{
	width: 100%;
	float: left;
	background: $brand-light-color;
}
.couple-desc{
	padding: 3em;
	@media screen and (max-width :$screen-sm) {
		padding: 1.5em;
	}
	h3{
		font-size: 40px;
	}
	.fh5co-social-icons{
		a{
			&:first-child{
				padding-left: 0;
			}
			i{
				@media screen and (max-width :$screen-sm) {
					font-size: 20px;
				}
			}
		}
	}
}


//Events
.wedding-events{
	background: $brand-white;
	.ceremony-bg{
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		position: relative;
		height: 400px;
	}
	.desc{
		padding: 2em;
		h3{
			font-size: 30px;
		}
		p{
			strong{
				font-size: 14px;
			}
		}
	}
}


#fh5co-started {
	position: relative;
	background-size: cover;
	background-position: bottom center;
	background-repeat: no-repeat;
	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, .5);
	}

	.form-control {
		background: rgba(0,0,0,.2);
		border: none!important;
		color: $brand-white;
		font-size: 16px!important;
		width: 100%;


		&::-webkit-input-placeholder {
		   color: $brand-white;
		}

		&:-moz-placeholder { /* Firefox 18- */
		   color: $brand-white;  
		}

		&::-moz-placeholder {  /* Firefox 19+ */
		   color: $brand-white;  
		}

		&:-ms-input-placeholder {  
		   color: $brand-white;  
		}
		@include transition(.5s);
		&:focus {
			background: rgba(255,255,255,.3);
		}

	}
	.btn-block {
		height: 54px;
		border: none!important;
		background: $brand-primary;
		color: $brand-white;
		font-size: 16px;
		text-transform: uppercase;
		font-weight: 400;
		padding-left: 50px;
		padding-right: 50px;
	}
	.form-inline {
		.form-group {
			width: 100%!important;
			margin-bottom: 10px;
			.form-control {
				width: 100%;
				padding: 27px 20px !important;
			}
		}
	}
	.heading-section {
		margin-bottom: 30px;
		h2 {
			margin-bottom: 20px;
			color: rgba($brand-white,.9);
		}
		p{
			color: rgba($brand-white,.7);
		}
	}
}


//GUEST
.groom-men{
	img{
		margin-bottom: 20px;
		@include border-radius(50%);
	}
	h3{
		font-family: $font-primary;
		font-size: 18px;
	}
}

//Gallery
.gallery{
	margin-bottom: 30px;
	.gallery-img{
		width: 100%;
		float: left;
		overflow: hidden;
		position: relative;
		z-index: 1;
		margin-bottom: 25px;
		img {
			position: relative;
			max-width: 100%;
			-webkit-transform: scale(1.0);
			-moz-transform: scale(1.0);
			-ms-transform: scale(1.0);
			-o-transform: scale(1.0);
			transform: scale(1.0);
			@include transition(.9s);
		}
		&:hover{
			img{
				-webkit-transform: scale(1.1);
				-moz-transform: scale(1.1);
				-ms-transform: scale(1.1);
				-o-transform: scale(1.1);
				transform: scale(1.1);
			}
		}
	}
}

.fh5co-blog{
	margin-bottom: 60px;
	a{
		img{
	
			width: 100%;

			&:hover{
			}

		}
	}

	@media screen and (max-width: $screen-sm) {
		width: 100%;
	}
	

	.blog-text {
		margin-bottom: 30px;
		position: relative;
		background: $brand-white;
		width: 100%;
		padding: 30px;
		float: right;

		span{
			display: inline-block;
			margin-bottom: 20px;
			font-size: 18px;
			&.by{
				color: rgba($brand-black,.5);
				margin-right: 7px;
			}
			&.posted_date{
				color: rgba($brand-black,.5);
				margin-right: 7px;
			}
			&.comment{
				a{
					color: rgba($brand-black,.3);
					i{
						color: $brand-primary;
						padding-left: 4px;
					}
				}
			}
		}

		h3{
			font-size: 20px;
			margin-bottom: 20px;
			font-weight: 300;
			line-height: 1.5;
			font-family: $font-primary;
			a{
				color: rgba($brand-black,.6);
			}
		}
	}
}



// Map
#map {
	width: 100%;
	height: 500px;
	position: relative;
	@media screen and (max-width: $screen-sm) {
		height: 200px;
	}
}

.fh5co-social-icons {
	> a {
		padding: 4px 10px;
		color: $brand-primary;

		i{
			font-size: 26px;
		}
		&:hover, &:focus, &:active {
			text-decoration: none;
			color: $brand-darker;
		}
	}
}

#footer{
	background: $brand-primary;
	padding: 60px 0 40px;

	p{
		color: rgba($brand-white,1);
		font-size: 14px;
		a{
			color: rgba($brand-white,.5);
			&:hover, &:active, &:focus {
				color: rgba($brand-white,.9)!important;
			}
		}
	}
	h2{
		color: $brand-white;
		font-size: 60px;
	}
}

.btn {
	text-transform: uppercase;
	letter-spacing: 2px;
	@include transition(.3s);
	&.btn-primary {
		background: $brand-primary;
		color: $brand-white;
		border: none!important;
		border: 2px solid transparent!important;
		&:hover, &:active, &:focus {
			box-shadow: none;
			background: $brand-primary;
			color: $brand-white!important;
		}
	}
	&:hover, &:active, &:focus {
		background: #393e46!important;
		color: $brand-white;
		outline: none!important;
	}
	&.btn-default {
		&:hover, &:focus, &:active {
			border-color: transparent;
		}
	}
}

.animate-box {
	.js & {
		opacity: 0;
	}
}



#fh5co-wrapper {
	overflow-x: hidden;
	position: relative;
}
.fh5co-offcanvas {
	overflow: hidden;
	#fh5co-wrapper {
		overflow: hidden!important;
	}
}

#fh5co-page {
	position: relative;
	@include transition(.5s);
	.fh5co-offcanvas & {
		@include fh5co-translateX(-240px);
	}
}

// offcanvas
#fh5co-menu-wrap {
	@media screen and (max-width: $screen-sm) {
		display: none;
	}
}
#offcanvas-menu {
	position: fixed;
	top: 0;
	bottom: 0;
	z-index: 99;
	background: #222;
	right: -240px;
	width: 240px;
	padding: 20px;
	overflow-y: scroll!important;
	display: none;
	@media screen and (max-width: $screen-sm) {
		display: block;
	}
	ul {
		padding: 0;
		margin: 0;
		li {
			padding: 0;
			margin: 0 0 10px 0;
			list-style: none;
			a {
				font-size: 18px;
				color: rgba(255,255,255,.4);
				&:hover {
					color: $brand-primary;
				}
			}
			> .fh5co-sub-menu {
				margin-top: 9px;
				padding-left: 20px;
			}
			&.active {
				> a {
					color: $brand-primary;
				}
			}
		}
		.fh5co-sub-menu {
			display: block!important;
		}
	}
}



// Burger Menu
.fh5co-nav-toggle {
 
  cursor: pointer;
  text-decoration: none;
  &.active i {
		&::before, &::after {
			background: $brand-white;
		}
  }
  &.dark {
  	&.active i {
			&::before, &::after {
				background: $brand-white;
			}
	  }
  }
  &:hover, &:focus, &:active {
  	outline: none;
  	border-bottom: none!important;
  }
  i {
  	position: relative;
	  @include inline-block;
	  width: 30px;
	  height: 2px;
	  color: $brand-white;
	  font:bold 14px/.4 Helvetica;
	  text-transform: uppercase;
	  text-indent:-55px;
	  background: $brand-white;
	  transition: all .2s ease-out;
		 &::before, &::after {
	  	  content:'';
		  width: 30px;
		  height: 2px;
		  background: $brand-white;
		  position: absolute;
		  left:0;
		  @include transition(.2s);
	  }
  }
  &.dark {
  	i {
	  	position: relative;
		  color: $brand-black;
		  background: $brand-black;
		  transition: all .2s ease-out;
			 &::before, &::after {
			  background: $brand-black;
			  @include transition(.2s);
		  }
	  }
  }
}

.fh5co-nav-toggle i::before {
  top: -7px;
}
.fh5co-nav-toggle i::after {
  bottom: -7px;
}
.fh5co-nav-toggle:hover i::before {
  top: -10px;
}
.fh5co-nav-toggle:hover i::after {
  bottom: -10px;
}
.fh5co-nav-toggle.active i {
	background: transparent;
}
.fh5co-nav-toggle.active i::before {
  top:0;
  -webkit-transform: rotateZ(45deg);
     -moz-transform: rotateZ(45deg);
      -ms-transform: rotateZ(45deg);
       -o-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}
.fh5co-nav-toggle.active i::after {
  bottom:0;
  -webkit-transform: rotateZ(-45deg);
     -moz-transform: rotateZ(-45deg);
      -ms-transform: rotateZ(-45deg);
       -o-transform: rotateZ(-45deg);
          transform: rotateZ(-45deg);
}
.fh5co-nav-toggle {
  	position: absolute;
  	top: 0px;
	right: 0px;
	z-index: 9999;
	display: block;
	margin: 0 auto;
	display: none;
	cursor: pointer;
  	@media screen and (max-width: $screen-sm) {
  		display: block;
  		top: 0px;
  		margin-top: 2em;
  	}
  
}



// // Helper Class

.col-xxs-12 {
	@media screen and (max-width: 480px) {
		float: none;
		width: 100%;
	}
}

.row-bottom-padded-lg {
	padding-bottom: 7em;
	@media screen and (max-width: $screen-sm ) {
		padding-bottom: 2em;
	}
}
.row-top-padded-lg {
	padding-top: 7em;
	@media screen and (max-width: $screen-sm ) {
		padding-top: 2em;
	}
}
.row-bottom-padded-md {
	padding-bottom: 4em;
	@media screen and (max-width: $screen-sm ) {
		padding-bottom: 2em;
	}	
}
.row-top-padded-md {
	padding-top: 4em;
	@media screen and (max-width: $screen-sm ) {
		padding-top: 2em;
	}	
}
.row-bottom-padded-sm {
	padding-bottom: 2em;
	@media screen and (max-width: $screen-sm ) {
		padding-bottom: 2em;
	}	
}
.row-top-padded-sm {
	padding-top: 2em;
	@media screen and (max-width: $screen-sm ) {
		padding-top: 2em;
	}	
}

.nopadding{
	padding: 0 !important;
	margin: 0 !important;
}
.padding{
	padding-left: 15px !important;
	padding-right: 15px !important;
}

.border-bottom {
	border-bottom: 1px solid rgba($brand-black,.06);
}

.lead {
	line-height: 1.8;
}

.no-js #loader { 
	display: none;  
}
.js #loader { 
	display: block; position: absolute; left: 100px; top: 0; 
}
.fh5co-loader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: url(../images/hourglass.svg) center no-repeat #222222;
}
// @import "bootstrap-compass";
// @import "bootstrap-mincer";
// @import "bootstrap-sprockets";
// @import "bootstrap";

